import * as React from "react"
import { graphql, Link } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PostCard from "../components/postcard"

const Navigation = ({ pageContext }) => {
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/" : `/page/${(currentPage - 1)}`
  const nextPage = `/page/${(currentPage + 1)}`

  return (
    <>
    {!isFirst && (
      <Link to={prevPage} rel="prev" style={{float: `left`}}>
        ← Página anterior
      </Link>
    )}
    {!isLast && (
      <Link to={nextPage} rel="next" style={{ float: `right`}}>
        Página siguiente →
      </Link>
    )}
    </>
  )
}
const BlogList = ({ data, location, pageContext }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMdx.nodes
  const menuLinks = data.site.siteMetadata.menuLinks

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle} menuLinks={menuLinks}>
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle} menuLinks={menuLinks}>
      <Bio />
      <ol style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug

          return (<PostCard title={title} post={post} />)
        })}
      </ol>
      <Navigation pageContext={pageContext} />
    </Layout>
  )
}


export const Head = ({ location, params, data, pageContext }) => {
  return (
    <Seo
      title={data.site.siteMetadata.title}
    />
  )
}
export default BlogList

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
        menuLinks {
          name
          link
        }
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          coverImage {
            childImageSharp {
              gatsbyImageData(
                width: 700
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
