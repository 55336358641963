import * as React from "react"
import { Link  } from "gatsby"
import PostImage from "./postimage"

const PostCard = ({ title, post }) => {
  return (
    <li key={post.fields.slug}>
      <article
        className="post-list-item"
        itemScope
        itemType="http://schema.org/Article"
      >
        <Link to={post.fields.slug} itemProp="url">
          <header>
            <PostImage coverImage={post.frontmatter.coverImage} className="post-list-image" />
            <div className="post-list-header">
              <h2>
                  <span itemProp="headline">{title}</span>
              </h2>
              <small>{post.frontmatter.date}</small>
            </div>
          </header>
          <section className="post-list-section">
            <p
              dangerouslySetInnerHTML={{
                __html: post.frontmatter.description || post.excerpt,
              }}
              itemProp="description"
            />
          </section>
        </Link>
      </article>
    </li>
  )
}

export default PostCard
